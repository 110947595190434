<template>
  <div ref="galleryContainerRef" class="relative min-w-32">
    <div class="red-shape"></div>

    <transition-group tag="div" class="slider-wrapper bg-white h-full w-full" :name="animDirection">
      <template v-for="(slide, index) in gallery">
        <div
          v-if="index === currentIndex && slide.gallery"
          :key="index"
          class="gallery-image relative flex justify-center items-center"
          :class="{ 'ad-slide': slide.ad }">
          <div
            v-if="slide.ad"
            class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-500 flex justify-center items-center">
            <div :id="`gallery-id-${index}`" class="bfa-ad-slot bfa-ad-slot-transparent" />
          </div>
          <div v-if="slide.gallery">
            <slot />
          </div>
          <div v-else class="blur-bg blur absolute top-0 left-0 w-full h-full lazyload" :data-bg="blurImageStyle" />

          <picture v-if="slide.urlWebp || slide.url" class="relative max-h-full w-full">
            <source v-if="slide.urlWebp" type="image/webp" :data-srcset="slide.urlWebp" />
            <img class="lazyload max-h-full m-auto" :data-srcset="slide.url" :alt="slide.caption" />
          </picture>

          <div class="absolute left-0 bottom-0 bg-white text-md text-red text-center sm:text-left pt-2 pr-4">
            {{ currentIndex + 1 }}/{{ gallery.length }}
          </div>
        </div>
        <div
          v-else-if="index === currentIndex"
          :key="index * 1000"
          class="gallery-image relative flex justify-center items-center"
          :class="{ 'ad-slide': slide.ad }"
          @click.prevent="imageClick">
          <div
            v-if="slide.ad"
            class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-500 flex justify-center items-center">
            <div :id="`gallery-id-${index}`" class="bfa-ad-slot bfa-ad-slot-transparent" />
          </div>
          <div v-if="slide.gallery">
            <slot />
          </div>
          <div v-else class="blur-bg blur absolute top-0 left-0 w-full h-full lazyload" :data-bg="blurImageStyle" />

          <picture class="relative max-h-full w-full">
            <source v-if="slide.urlWebp" type="image/webp" :data-srcset="slide.urlWebp" />
            <img class="lazyload max-h-full m-auto" :data-srcset="slide.url" :alt="slide.caption" />
          </picture>

          <div class="absolute left-0 bottom-0 bg-white text-md text-red text-center sm:text-left pt-2 pr-4">
            {{ currentIndex + 1 }}/{{ gallery.length }}
          </div>
        </div>
        <div
          v-else-if="index === gallery.length"
          :key="index * 2000"
          class="gallery-image relative flex justify-center items-center"
          :class="{ 'ad-slide': slide.ad }">
          <div
            v-if="slide.ad"
            class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-500 flex justify-center items-center">
            <div :id="`gallery-id-${index}`" class="bfa-ad-slot bfa-ad-slot-transparent" />
          </div>
          <div v-if="slide.gallery">
            <slot />
          </div>
          <div v-else class="blur-bg blur absolute top-0 left-0 w-full h-full lazyload" :data-bg="blurImageStyle" />

          <picture class="relative max-h-full w-full">
            <source v-if="slide.urlWebp" type="image/webp" :data-srcset="slide.urlWebp" />
            <img class="lazyload max-h-full m-auto" :data-srcset="slide.url" :alt="slide.caption" />
          </picture>

          <div class="absolute left-0 bottom-0 bg-white text-md text-red text-center sm:text-left pt-2 pr-4">
            {{ currentIndex + 1 }}/{{ gallery.length }}
          </div>
        </div>
      </template>
    </transition-group>

    <div class="flex justify-center my-3">
      <button
        class="w-12 h-12 bg-black rounded-full mr-4 flex justify-center items-center text-white opacity-75"
        @click.prevent="prevSlide">
        <svg width="30" height="30">
          <use xlink:href="#arrow-left" />
        </svg>
      </button>
      <button
        class="w-12 h-12 bg-black rounded-full ml-4 flex justify-center items-center text-white opacity-75"
        @click.prevent="nextSlide">
        <svg width="30" height="30">
          <use xlink:href="#arrow-right" />
        </svg>
      </button>
    </div>

    <div class="gallery-info" :style="infoDivStyle" :class="[inArticle ? 'gallery-info-article' : '']">
      <div ref="infoRef">
        <h3 class="text-md leading-tight" :class="[inArticle ? 'mb-3' : 'mb-2 font-bold']">
          {{ currentImage.caption }}
        </h3>
        <small class="text-sm opacity-50">
          {{ currentImage.agencyCustom ? `&copy; ${currentImage.agencyCustom}` : '' }}
        </small>

        <div v-if="currentImage.article" class="w-1/2 mx-auto mt-6">
          <h3 class="uppercase text-2xl font-bold mb-2 headline-dot">Zum Artikel</h3>
          <a :href="currentImage.article.link" class="block border border-grey-100 p-4 leading-tight">
            <span class="text-vertical max-lines max-lines-1 text-base">{{ currentImage.article.kicker }}</span>
            <div class="font-bold text-md sm:text-lg max-lines max-lines-2">{{ currentImage.article.title }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, onUpdated } from '@vue/composition-api';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import TeaserTrackingUtils from '@/utils/tracking/teaserTracking.utils';
import { addAdSlot, removeAdSlot } from '@/utils/ads/BFAAds';
import * as ivw from '@/utils/ivw.utils';
import CommonUtils from '@/utils/common.utils';

export default defineComponent({
  name: 'gallery-slider',
  props: {
    gallery: {
      type: Array as () => Array<any>,
      required: true,
    },
    galleryName: {
      type: String,
      required: true,
    },
    galleryId: {
      type: String,
      required: true,
    },
    inArticle: {
      type: Boolean,
    },
    adOrientation: {
      type: String,
      required: true,
    },
    relatedGalleryUuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const infoRef = ref<HTMLElement | null>(null);
    const galleryContainerRef = ref<HTMLElement | null>(null);
    const infoDivHeight = ref(0);
    const calcInfoHeight = () => {
      nextTick(() => (infoDivHeight.value = infoRef.value?.scrollHeight || 0));
    };
    const infoDivStyle = computed(() => `height: ${infoDivHeight.value ? `${infoDivHeight.value}px` : '0px'};`);

    const currentIndex = ref(0);
    const animDirection = ref('slide');
    const adSlotRef = ref('');
    const slideshowMaxIndex = ref(props.gallery.length - 1);

    const currentImage = computed(() => props.gallery[currentIndex.value]);
    const activeImageStyle = computed(() => `background-image: url("${currentImage.value.url}");`);
    const blurImageStyle = computed(
      () => `${CommonUtils.isSafari() ? currentImage.value.url : currentImage.value.urlWebp || currentImage.value.url}`
    );

    const handleNewSlide = () => {
      calcInfoHeight();
      if (adSlotRef.value) {
        removeAdSlot(adSlotRef.value);
        adSlotRef.value = '';
      }
      if (currentImage.value.ad) {
        const adSlotId = `gallery-id-${currentIndex.value}`;
        nextTick(() => {
          addAdSlot(adSlotId, props.adOrientation, currentImage.value.adIndex);
          adSlotRef.value = adSlotId;
        });
      } else {
        TrackingUtils.trackEvent({
          event: 'b_gallery_view',
          eventCategory: 'gallery.view',
          eventAction: props.galleryName,
          eventLabel: `pic_${currentImage.value.index + 1}`,
        });
        ivw.pageView();
        TrackingUtils.trackPageView(location.pathname);
      }
    };

    const imageClick = (event: any) => {
      if (currentIndex.value === slideshowMaxIndex.value) {
        event.stopPropagation();
        return;
      }

      if (galleryContainerRef.value?.clientWidth) {
        if (event.offsetX < galleryContainerRef.value?.clientWidth / 2) {
          prevSlide();
        } else {
          nextSlide();
        }
      }
    };

    const prevSlide = () => {
      animDirection.value = 'slideback';
      if (currentIndex.value === 0) {
        currentIndex.value = slideshowMaxIndex.value;
      } else {
        currentIndex.value--;
      }
      handleNewSlide();
    };

    const nextSlide = () => {
      animDirection.value = 'slide';
      if (currentIndex.value < slideshowMaxIndex.value) {
        currentIndex.value++;
      } else if (currentIndex.value === slideshowMaxIndex.value && props.relatedGalleryUuid) {
        window.location.href = `/gallery/${props.relatedGalleryUuid}.html`;
      } else {
        currentIndex.value = 0;
      }
      handleNewSlide();
    };

    onMounted(() => {
      calcInfoHeight();
      TeaserTrackingUtils.manualTeaserTracking('open_gallery', `/gallery/${props.galleryId}.html`);
      TrackingUtils.trackEvent({
        event: 'b_gallery_open',
        eventCategory: 'gallery.open',
        eventAction: props.galleryName,
      });
      TrackingUtils.trackEvent({
        event: 'b_gallery_view',
        eventCategory: 'gallery.view',
        eventAction: props.galleryName,
        eventLabel: `pic_${currentImage.value.index + 1}`,
      });
      ivw.pageView();
      TrackingUtils.trackPageView(location.pathname);
    });

    return {
      infoRef,
      galleryContainerRef,
      infoDivHeight,
      infoDivStyle,
      blurImageStyle,
      currentImage,
      currentIndex,
      animDirection,
      adSlotRef,
      activeImageStyle,
      slideshowMaxIndex,
      prevSlide,
      nextSlide,
      imageClick,
    };
  },
});
</script>

<style scoped>
.red-shape {
  width: 17rem;
  margin-top: -1.25rem;
}

.gallery-image {
  height: 650px;
}

.ad-slide picture {
  height: 100%;
}

.blur-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.blur-bg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  backdrop-filter: blur(10px); /* apply the blur */
  pointer-events: none; /* make the overlay click-through */
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}

.slider-wrapper {
  position: relative;
  height: 650px;
  overflow: hidden;
}
.gallery-image {
  position: absolute;
  width: 100%;
}
.gallery-info {
  transition: height 0.6s ease-in-out;
}
</style>
